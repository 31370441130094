import React, {Component} from 'react';
import Product from "./components/Product";
import products from "./data-single.json"
import bulk from "./data-bulk.json"

// const meals = [
//     { calorie: 10},
//     { calorie: 15},
//     { calorie: 20}
// ];
//
// const calorieTotal = meals.reduce((totalCalories, meal) => totalCalories + meal.calorie, 0);
//
// console.log(calorieTotal); // 45 calories


class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cart: [],
        };
    }


    // addToCart(id, code, name, price, image, description, units, quantity) {
    //     this.setState(
    //         {
    //                 id: id,
    //                 code: code,
    //                 name: name,
    //                 price: price,
    //                 image: image,
    //                 description: description,
    //                 units: units,
    //                 quantity: quantity
    //         },
    //
    //         function () {
    //             console.log(this.state)
    //         }
    //     );
    //     this.setState(
    //         {
    //             isAdded: true
    //         },
    //         function () {
    //             setTimeout(() => {
    //                 this.setState({
    //                     isAdded: false,
    //                     selectedProduct: {}
    //                 });
    //             }, 3500);
    //         }
    //     );
    // }

    handleAddFunc(product) {
        const existingProduct = this.state.cart.filter(p => p.id === product.id);

        if (existingProduct.length > 0) {
            const withoutExistingProduct = this.state.cart.filter(p => p.id !== product.id);
            const updatedUnitsProduct = {
                ...existingProduct[0],
                units: existingProduct[0].units + product.units
            };
            this.setState({
                cart: [...withoutExistingProduct, updatedUnitsProduct]
            });
        } else {
            this.setState({
                cart: [...this.state.cart, product]
            });
        }
    }

    render() {
        console.log(this.state.cart)
        return (
            <div className="container-fluid">
                <nav className="navbar navbar-expand-lg fixed-top navbar-light bg-white shadow">
                    {/*<a className="navbar-brand" href="#">Navbar</a>*/}
                    <div className="navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item mr-md-5 mr-4">
                                <a className="nav-link" href="#">Join Us</a>
                            </li>
                            <li className="nav-item">
                                <a href="#" type="button" className="btn btn-custom">
                                    Order Now <span className="badge badge-light">{this.state.cart.length}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>

                <div className="row">
                    <div className="col-12 col-md-6 text-center bg-indigo">
                        <div className="p-5">
                            <div className="text-center"><img src={require('./assets/logoclear.png')} width={'100%'}
                                                              alt=""/></div>
                            <div className="my-5">
                                <img src={require('./assets/locally-hand-made-new.png')} width={'60%'} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 loginarea"></div>
                </div>

                <div className="container mb-5">
                    <div className="col-12 p-0 my-4">
                        <img src={require('./assets/banner.png')} width={'100%'}
                             className="card-img-top" alt="..."/>
                    </div>

                    <div className="row row-cols-1 row-cols-md-3 mb-5">
                        {
                            products.map(p => <Product key={p.id} {...p} addFunc={this.handleAddFunc.bind(this)}/>)
                        }
                    </div>

                    {/*Wholesale*/}
                    <h2 className="display-text-purple">Wholesale</h2>
                    <div className="row row-cols-1 row-cols-md-3">
                        {
                            bulk.map(b => <Product key={b.id} {...b} addFunc={this.handleAddFunc.bind(this)}/>)
                        }
                    </div>
                    {/*<ul>*/}
                    {/*    {*/}
                    {/*        this.state.cart.map(c => <li key={c.id}>{c.name} <br/> units {c.units} @ R{c.price}</li>)*/}
                    {/*    }*/}
                    {/*</ul>*/}
                </div>


                <section className="bg-deep-deep-purple text-white-50 text-center p-5">
                    <div className="my-3">
                    <h4>NEED EXTRA INCOME?</h4>
                    </div>

                    <div className="row row-cols-1 row-cols-md-3">
                    <div className="col">
                        <img src={require('./assets/starter-kit-front.jpg')} alt="..." className="img-thumbnail shadow" />
                    </div>
                        <div className="col">
                            <img src={require('./assets/starter-kit-middle.jpg')} alt="..." className="img-thumbnail shadow" />
                        </div>
                        <div className="col">
                            <img src={require('./assets/starter-kit-back.jpg')} alt="..." className="img-thumbnail shadow" />
                        </div>
                    </div>
                </section>

                <footer className="py-3 bg-indigo">
                    <div className="text-center text-white"><small>MADE WITH <img src={require('./assets/red-heart.png')} width={15}
                                                                alt=""/> IN SOUTH AFRICA</small>
                    </div>
                </footer>
            </div>
        );
    }
}

export default App;
