import React from 'react';

const Product = ({id, code, name, price, image, header1, description1, header2, description2, header3, description3, units, quantity, addFunc}) => {

    return (
        <div className="col mb-4">
            <div className="card shadow-sm border-0">
                <img src={require('../assets/' + image)} className="card-img-top bg-light" alt={name}/>
                <div className="card-body">
                    <h6 className="card-title display-item-header">{name}</h6>
                    <div className="mt-3 mb-0">Consist of:</div>
                    <div className=" mt-0 mb-0">
                        <b className="text-muted">{header1}</b>
                        <p className="card-text product_description muted mb-0">{description1}</p>
                        <b className="text-muted">{header2}</b>
                        <p className="card-text product_description muted mb-0">{description2}</p>
                        <b className="text-muted">{header3}</b>
                        <p className="card-text product_description muted mb-0">{description3}</p>
                    </div>
                    <div className="">
                        <small><b>Product Code: {code}</b></small>
                    </div>
                    {/*<div className="mb-3">*/}
                    {/*    <small><b>{units}</b></small>*/}
                    {/*</div>*/}
                    <div className="text-right">
                        <h5 className="display-x mb-0">R {price}</h5>
                        <small>ex. VAT</small>
                    </div>
                </div>
                <div className="card-footer border-0 text-center">
                    <button className="btn btn-custom px-5" onClick={() => addFunc({
                        id,
                        code,
                        name,
                        price,
                        image,
                        header1,
                        description1,
                        header2,
                        description2,
                        header3,
                        description3,
                        quantity,
                        units: 1
                    })}>
                        Add to Order
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Product
